const navbar = document.getElementById('navbar-home') || document.getElementById('navbar');
const menuTrigger = document.getElementById('menu-trigger');
const menuElement = document.getElementById("menu");

menuTrigger.addEventListener("click", toggleMenu);

function toggleMenu() {
    if (menuElement) {
        menuElement.classList.toggle("!opacity-0");
        menuElement.classList.toggle("pointer-events-none");
    }
}

let searchOpen = false;
const searchElement = document.getElementById("search-icon");
const searchInput = document.getElementById("q");
const searchBar = document.getElementById("search-bar");

searchElement.addEventListener("click", () => { toggleSearch(), toggleFocus() });
searchElement.addEventListener("keydown", (e) => { 
        console.log(e.key);
        if (e.key === "Tab") {
            toggleSearch()
}});

document.addEventListener("click", (event) => {
    if (searchOpen && !searchBar.contains(event.target) && !searchElement.contains(event.target)) {
        toggleSearch();
    }
});

function toggleSearch() {
    searchElement.classList.toggle("hidden");
    searchBar.classList.remove(searchOpen ? 'w-full': 'w-0');
    searchBar.classList.add(searchOpen ? 'w-0': 'w-full');
    searchOpen = !searchOpen;
}

function toggleFocus() {
    searchInput.focus();
}

